import TitleBar from "./TitleBar";

import MainImage from "../res/spacedemo_main.png";
import DarienCT from "../res/darienct.jpg";
import Tetris from "../res/tetris.png";

import * as page from "./Page.js";
import Thesis from "../res/thesis.png";

function HomePage() {
    return (
        <page.Page className="pages_HomePage">
            <page.PageTitle>Home</page.PageTitle>
            <page.PageImagePane src={MainImage} title="Welcome to my Website!">
                This website is the hub for all my current projects as well as info about me. More about me can be found
                in the "About Me" section, my projects and Github link can be found in "My Projects", and several links
                are provided below to different sites and projects.
            </page.PageImagePane>
            <page.PageTitle>Quick Links</page.PageTitle>
            <div className="pages_BentoGrid">
                <page.BentoBox to="/about" img={DarienCT} grow={1}>About Me</page.BentoBox>
                <page.BentoBox to="/projects" img={Tetris} grow={6}>My Projects</page.BentoBox>
            </div>
            <div className="pages_BentoGrid">
                <page.BentoBox to="https://scholarsarchive.byu.edu/studentpub_uht/366/"
                               img={Thesis}
                               grow={6}>Undergrad Thesis
                </page.BentoBox>
                <page.BentoBox
                    to="https://www.gramvikas.org/blg/gram-vikas-and-brigham-young-university-collaborate-to-harness-data-for-water-security-in-rural-odisha/"
                    img={"https://www.gramvikas.org/wp-content/themes/gramvikas/img/GV-logo.png"}
                    grow={1}>Gram Vikas Internship
                </page.BentoBox>
            </div>
            <div className="pages_BentoGrid">
                <page.BentoBox to="https://today.uconn.edu/2019/03/innovating-wellness-hackuconn-2019/"
                               img={"https://today.uconn.edu/wp-content/uploads/2019/03/HackUConn_IMG_4988.jpg"}
                               grow={1}>Hack UConn 2019
                </page.BentoBox>
                <page.BentoBox to="https://innovationquest.uconn.edu/previous-winners/"
                               img={"https://innovationquest.media.uconn.edu/wp-content/uploads/sites/1160/2022/01/iQ-banner-2048x796.jpg"}
                               grow={6}>Innovation Quest 2019
                </page.BentoBox>
            </div>
        </page.Page>
    );
}

export default HomePage;