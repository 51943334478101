import './about.css';

import TitleBar from "./TitleBar";
import * as page from "./Page.js";

import Darien from "../res/darien2.jpg";
import UConnCampus from "../res/uconn_campus.png";
// import HackUConn from "";
// import IQuest from "";

import GithubLogo from "../res/github-mark.svg";
import DocIcon from "../res/document.svg";
import LinkedInIcon from "../res/LI-In-Bug.png";
import TimelineBorder from "../res/timeline_border.svg";
import TimelinePattern from "../res/timeline_pattern.svg";
import Thesis from "../res/thesis.png";

function AboutPage() {
    return (
        <page.Page className="pages_AboutPage">
            <page.PageTitle>About Me</page.PageTitle>
            <ProfileImage img={Darien}>Darien Campbell</ProfileImage>
            <div className="pages_ChipRow">
                <page.Chip href="https://github.com/darienac" icon={GithubLogo}>Github</page.Chip>
                <page.Chip href="/resume" icon={DocIcon}>Resume</page.Chip>
                <page.Chip href="https://www.linkedin.com/in/darien-campbell/" icon={LinkedInIcon}>LinkedIn</page.Chip>
            </div>
            <page.PageTitle>Timeline</page.PageTitle>
            <Timeline />
        </page.Page>
    );
}

function ProfileImage(props) {
    return (
        <div className="about_ProfileImage">
            <img src={props.img} />
            <div>{props.children}</div>
        </div>
    );
}

function Timeline(props) {
    return (
        <div className="about_Timeline">
            <div className="about_Timeline_Line" style={{backgroundImage: "url(" + TimelineBorder + ")"}}></div>
            <div className="about_Timeline_Content">
                <TimelineEntry grid={
                    <div className="pages_BentoGrid">
                        <page.BentoBox to="https://today.uconn.edu/2019/03/innovating-wellness-hackuconn-2019/"
                                       img={"https://today.uconn.edu/wp-content/uploads/2019/03/HackUConn_IMG_4988.jpg"}
                                       grow={1}>Hack UConn 2019
                        </page.BentoBox>
                        <page.BentoBox to="https://innovationquest.uconn.edu/previous-winners/"
                                       img={"https://innovationquest.media.uconn.edu/wp-content/uploads/sites/1160/2022/01/iQ-banner-2048x796.jpg"}
                                       grow={6}>Innovation Quest 2019
                        </page.BentoBox>
                    </div>
                } title="Studied at UConn" date="Aug 2018 - May 2019" imageSrc={UConnCampus} imageAlt="UConn Campus">
                    I started my undergrad education in Computer Science at UConn. UConn was a launching point for me to
                    learn the fundamentals of Computer Science and programming and to already make an impact by applying
                    the skills I gained.<br/><br/>
                    While at UConn, I built my first full stack web app, Laundry Check, a way to remotely check on
                    available laundry machines in a college dorm. I also joined a team in UConn's 2019 Hackathon to
                    create a robotic pill dispenser device to help address opioid misuse. We later took this concept to
                    UConn's Innovation Quest event in 2019 and won 3rd place.
                </TimelineEntry>
                <TimelineEntry grid={
                    null
                } title="Church Proselytizing Mission" date="June 2019 - June 2021" imageSrc="https://images.ctfassets.net/0wjmk6wgfops/1N5EjGJ8Ce2ntxbTtUVfkY/a70bd275b8be7725fc48fc95e86f287a/AdobeStock_615586339.jpeg?w=1200&h=630&f=center&fit=fill" imageAlt="St George, Utah">
                    From 2019 to 2021 I served a mission for the Church of Jesus Christ of Latter-day Saints. This was
                    a full break from school or any other work besides sharing messages about Jesus Christ for the whole
                    two years. Although it was difficult for me to leave for so long, the experience has really
                    strengthened my faith and improved my ability to socialize and connect with others around me.
                </TimelineEntry>
                <TimelineEntry grid={
                    <div className="pages_BentoGrid">
                        <page.BentoBox to="https://scholarsarchive.byu.edu/studentpub_uht/366/"
                                       img={Thesis}
                                       grow={1}>Undergrad Thesis
                        </page.BentoBox>
                        <page.BentoBox to="https://www.gramvikas.org/blg/gram-vikas-and-brigham-young-university-collaborate-to-harness-data-for-water-security-in-rural-odisha/"
                                       img={"https://www.gramvikas.org/wp-content/themes/gramvikas/img/GV-logo.png"}
                                       grow={1}>Gram Vikas Internship
                        </page.BentoBox>
                    </div>
                } title="Bachelors in CS at BYU Provo" date="Aug 2021 - April 2024" imageSrc="https://brightspotcdn.byu.edu/dims4/default/b0c764e/2147483647/strip/true/crop/2400x1599+0+0/resize/840x560!/quality/90/?url=https%3A%2F%2Fbrigham-young-brightspot-us-east-2.s3.us-east-2.amazonaws.com%2F45%2F20%2Fc6a10d254b90845b5c938bc419c7%2F1810-70-0120.JPG" imageAlt="BYU Provo Campus">
                    I finished my bachelors in Computer Science at BYU after my mission, graduating Cum Laude, with
                    University Honors, and with a minor in Mathematics.<br/><br/>
                    The University Honors program at BYU was especially impactful to me and my education. As part of the
                    program, I gained leadership experience through an internship with Gram Vikas providing data analysis
                    for water data in villages across villages in India.<br/><br/>
                    During my last two years at BYU, I researched and completed an undergraduate thesis in Explainable AI.
                    Opportunities like this and others in the Honors program helped me learn how to apply skills from my
                    classes to real world problems and to academic problems without known solutions.
                </TimelineEntry>
                <TimelineEntry grid={
                    null
                } title="Masters in CS at Boston University" date="Aug 2024 - May 2025" imageSrc="https://www.bu.edu/admissions/files/2023/09/22-1623-AERIAL-282-1024x358.png" imageAlt="Boston University Campus">
                    By this May I am planning on graduating from Boston University with a Masters in Computer Science
                    with an emphasis in data-centric computing. The classes I have taken at Boston University have
                    improved my skills with app development in Kotlin, distributed systems in Go, stream processing in
                    Java with Flink and Kafka, and Machine Learning with Numpy and Pytorch.
                </TimelineEntry>
            </div>
        </div>
    );
}

function TimelineEntry(props) {
    return (
        <div className="about_Timeline_Entry">
            <h1>
                <div>{props.date}</div>
                <div style={{background: "linear-gradient(to right, transparent, var(--color-page)), url(" + TimelinePattern + ")"}}></div>
            </h1>
            <h2>{props.title}</h2>
            <div>
                <p>{props.children}</p>
                <img alt={props.imageAlt} src={props.imageSrc}/>
            </div>
            <div>
                {props.grid}
            </div>
        </div>
    );
}

export default AboutPage;