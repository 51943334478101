import {NavLink} from "react-router-dom";
import {useState} from 'react';


import './TitleBar.css';

function TitleBar(props) {
    const [menuActive, setMenuActive] = useState(undefined);

    function menuClick() {
        setMenuActive(!menuActive);
    }

    function handleDarkModeButtonClick() {
        props.onDarkModeChange(!props.darkMode);
    }

    return (
        <div className="home home_TitleBar">
            <div className="home_TitleBarContent">
                <div className="home_TitleBar_Title">Darien Campbell</div>
                <div className="home_TitleBar_Options">
                    <Option to="/">Home</Option>
                    <Option to="/about">About Me</Option>
                    <Option to="/projects">My Projects</Option>
                    <IconButton onClick={handleDarkModeButtonClick}>{props.darkMode ? "dark_mode" : "light_mode"}</IconButton>
                    <OptionsMenu onClick={menuClick} active={menuActive} />
                </div>
                <br />
                {menuActive ? <div className="home_TitleBar_VOptions">
                    <VOption to="/">Home</VOption>
                    <VOption to="/about">About Me</VOption>
                    <VOption to="/projects">My Projects</VOption>
                </div> : null}
            </div>
        </div>
    );
}

function IconButton(props) {
    return (
        <button className="home_TitleBar_IconButton" onClick={props.onClick}>
            <span class="material-symbols-outlined">{props.children}</span>
        </button>
    );
}

function Option(props) {
    return (
        <NavLink to={props.to} className="home_TitleBar_Option">{props.children}</NavLink>
    );
}

function VOption(props) {
    return (
        <NavLink to={props.to} className="home_TitleBar_VOption">{props.children}</NavLink>
    );
}

function OptionsMenu(props) {
    let className = "home_TitleBar_OptionsMenu";
    if (props.active) {
        className += " active";
    } else if (props.active === false) {
        className += " inactive";
    }

    function handleClick() {
        props.onClick(this);
    }

    return (
        <button className={className} onClick={handleClick}>
            <svg
                width="32"
                height="32"
                viewBox="0 0 67.733332 67.733333"
                version="1.1"
                id="svg5"
                xmlns="http://www.w3.org/2000/svg">
                <defs
                    id="defs2"/>
                <g
                    id="layer1">
                    <path
                        style={{fill:"#000000"}}
                        d="M 33.866666,16.933333 H 12.7 c 0,0 -4.2333334,0 -4.2333334,4.233333 C 8.4666666,25.4 12.7,25.4 12.7,25.4 h 21.166666 c 0,0 4.233334,0 4.233334,-4.233334 0,-4.233333 -4.233334,-4.233333 -4.233334,-4.233333 z"
                        className="home_TitleBar_OptionsMenu_Path1"/>
                    <path
                        style={{fill:"#000000"}}
                        d="M 55.033332,16.933333 H 33.866666 c 0,0 -4.233333,0 -4.233333,4.233333 0,4.233334 4.233333,4.233334 4.233333,4.233334 h 21.166666 c 0,0 4.233334,0 4.233334,-4.233334 0,-4.233333 -4.233334,-4.233333 -4.233334,-4.233333 z"
                        className="home_TitleBar_OptionsMenu_Path2"/>
                    <path
                        style={{fill:"#000000"}}
                        d="M 33.866665,42.333333 H 12.699999 c 0,0 -4.233332,0 -4.233332,4.233333 C 8.466667,50.8 12.699999,50.8 12.699999,50.8 h 21.166666 c 0,0 4.233334,0 4.233334,-4.233334 0,-4.233333 -4.233334,-4.233333 -4.233334,-4.233333 z"
                        className="home_TitleBar_OptionsMenu_Path3"/>
                    <path
                        style={{fill:"#000000"}}
                        d="M 55.033331,42.333333 H 33.866665 c 0,0 -4.233333,0 -4.233333,4.233333 0,4.233334 4.233333,4.233334 4.233333,4.233334 h 21.166666 c 0,0 4.233334,0 4.233334,-4.233334 0,-4.233333 -4.233334,-4.233333 -4.233334,-4.233333 z"
                        className="home_TitleBar_OptionsMenu_Path4"/>
                </g>
            </svg>
        </button>
    );
}

export default TitleBar;