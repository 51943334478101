import './TitleBar.css';
import './pages.css';

import {NavLink} from "react-router-dom";
import TitleBar from "./TitleBar";
import {useState} from "react";

function Page(props) {
    function getStoredDarkMode() {
        if (localStorage.getItem("darkMode") === null) {
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        } else {
            return localStorage.getItem("darkMode") === "true";
        }
    }

    const [darkMode, setDarkMode] = useState(getStoredDarkMode());

    function handleDarkModeChange(value) {
        localStorage.setItem("darkMode", value);
        setDarkMode(value);
    }

    return (
        <div className={"home home_Root " + props.className} darkMode={darkMode.toString()}>
            <TitleBar darkMode={darkMode} onDarkModeChange={handleDarkModeChange} />
            <div className="pages_Page">{props.children}</div>
        </div>
    );
}

function PageTitle(props) {
    return (
        <div className="pages_PageTitle">
            {props.children}<div />
        </div>
    );
}

function PageImagePane(props) {
    return (
        <div className="pages_PageImagePane">
            <img src={props.src} />
            <div>
                <h1>{props.title}</h1>
                <p>{props.children}</p>
            </div>
        </div>
    );
}

function BentoBox(props) {
    return (
        <NavLink to={props.to} className="pages_BentoBox" style={{backgroundImage: "url(" + props.img + ")", flexGrow: props.grow}}>
            <div>{props.children}</div>
        </NavLink>
    );
}

function Chip(props) {
    return (
        <a className="pages_Chip" href={props.href}>
            <img src={props.icon} />
            <div>{props.children}</div>
        </a>
    );
}

export {Page, PageTitle, PageImagePane, BentoBox, Chip};