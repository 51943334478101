import './project.css';

import TitleBar from "./TitleBar";

import * as page from "./Page.js";
import background1 from "../res/sunset.jpg";
import img_spacegame from "../res/spacegame.png";
import img_tetris from "../res/tetris_wide.png";
import img_prismatic from "../res/prismatic_wide.png";
import {useState} from "react";
import GithubLogo from "../res/github-mark.svg";

function ProjectPage() {
    return (
        <page.Page className="pages_ProjectPage">
            <page.PageTitle>My Projects</page.PageTitle>
            <ProjectSection title="Space Demo" image={img_spacegame} linkSource="https://github.com/darienac/spacegame">
                This is a demo I created to explore how I could create planets and stars that you could freely traverse
                between in 3D space. The demo includes keyboard and gamepad controls and includes features such as
                code reuse in shaders, dynamically generated planet surfaces using Perlin noise and rendering to textures,
                and realtime reflections using rendered cubemaps around the player ship.
            </ProjectSection>
            <ProjectSection title="Another Tetris Game" rtl={true} image={img_tetris} linkSource="https://github.com/darienac/AnotherTetrisGame">
                This is my own clone of Tetris written in Java and OpenGL which faithfully follows the Tetris guidelines
                including how scoring is done, piece rotations, and combos.
            </ProjectSection>
            <ProjectSection title="Prismatic" image={img_prismatic} linkSource="https://github.com/darienac/Prismatic">
                A simple game demo I designed for CS 455 at BYU. In Prismatic, you control a nameless character who uses color switches to manipulate what you can see and what's really there.
                <br /><br />
                Written in C++ and OpenGL using GLFW for window management and Assimp for loading models.
            </ProjectSection>
            <GithubSection />
        </page.Page>
    );
}

function ProjectSection(props) {
    const [viewDesc, setViewDesc] = useState(false);

    function handleExpandClick() {
        setViewDesc(!viewDesc);
    }

    let className = "project_ProjectSection";
    if (viewDesc) {
        className += " project_ProjectSection_viewDesc";
    }
    if (props.rtl) {
        className += " project_ProjectSection_rtl"; // TODO: implement this
    }
    let sideA = (
        <div className="project_ProjectWindow" style={{backgroundImage: `url(${props.image})`}}>
            <div className="project_ProjectWindow_Details">
                <h1>{props.title}</h1>
                <div className="project_ProjectWindow_Options">
                    {props.linkDemo ? <a href={props.linkDemo}>Live Demo<span
                        className="material-symbols-outlined">deployed_code</span></a> : null}
                    {props.linkSource ? <a href={props.linkSource}>View Source<span
                        className="material-symbols-outlined">code</span></a> : null}
                </div>
            </div>
        </div>
    );
    let sideB = (
        <p>
            <div>{props.children}</div>
        </p>
    );
    return (
        <div className={className}>
            {props.rtl ? sideB : sideA}
            <button className="project_ProjectSection_Expand" onClick={handleExpandClick}><span
                className="material-symbols-outlined">{(viewDesc ^ props.rtl) ? "chevron_right" : "chevron_left"}</span></button>
            {props.rtl ? sideA : sideB}
        </div>
    );
}

function GithubSection(props) {
    return (
        <div className="project_GithubSection">
            <div>View more projects on my Github</div>
            <page.Chip href="https://github.com/darienac" icon={GithubLogo}>Github</page.Chip>
        </div>

    );
}

export default ProjectPage;